@font-face {
  font-family: ShreenathBoldRegular;
  src: url("./components/assets/fonts/ShreenathBoldRegular.ttf");
}
@font-face {
  font-family: SailecBlackItalic;
  src: url("./components/assets/fonts/Sailec Black Italic.otf");
}
@font-face {
  font-family: SailecBlack;
  src: url("./components/assets/fonts/Sailec Black.otf");
}
@font-face {
  font-family: SailecBoldItalic;
  src: url("./components/assets/fonts/Sailec Bold Italic.otf");
}
@font-face {
  font-family: SailecBold;
  src: url("./components/assets/fonts/Sailec Bold.otf");
}
@font-face {
  font-family: SailecLightItalic;
  src: url("./components/assets/fonts/Sailec Light Italic.otf");
}
@font-face {
  font-family: SailecLight;
  src: url("./components/assets/fonts/Sailec Light.otf");
}
@font-face {
  font-family: SailecMediumItalic;
  src: url("./components/assets/fonts/Sailec Medium Italic.otf");
}
@font-face {
  font-family: SailecMedium;
  src: url("./components/assets/fonts/Sailec Medium.otf");
}
@font-face {
  font-family: SailecRegularItalic;
  src: url("./components/assets/fonts/Sailec Regular Italic.otf");
}
@font-face {
  font-family: SailecRegular;
  src: url("./components/assets/fonts/Sailec Regular.otf");
}
@font-face {
  font-family: SailecThinItalic;
  src: url("./components/assets/fonts/Sailec Thin Italic.otf");
}
@font-face {
  font-family: SailecThin;
  src: url("./components/assets/fonts/Sailec Thin.otf");
}
html {
  scroll-behavior: smooth;
}
a:hover {
  text-decoration: none !important;
  color: #fff !important;
}
.SailecMedium{
  font-family: SailecMedium;
}
.fontShareenath {
  font-family: ShreenathBoldRegular !important;
}
.fontSailecRegular {
  font-family: SailecRegular !important;
}
.fontSailecMedium {
  font-family: SailecMedium !important;
}
.fontSailecLight {
  font-family: SailecLight !important;
}
.fontSailecBold {
  font-family: SailecBold !important;
}
.text-white {
  color: #ffffff !important;
}
.borRadius5 {
  border-radius: 5px !important;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
  border: none !important;
  outline: none !important;
}
.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: SailecMedium !important;
  font-size: 14px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
  border: 1px solid #f0f0f0 !important;
  background-color: #faf9f7 !important;
}
.MuiPaper-root.root {
  padding: 2px 4px;
  display: flex;
  align-items: center;
}
.MuiPaper-elevation1.error {
  border: 1px solid red !important;
}

.MuiOutlinedInput-input {
  border-radius: 5px !important;
}
.MuiOutlinedInput-input::placeholder {
  font-size: 14px !important;
  color: #8d92a3 !important;
  opacity: 1 !important;
  font-family: SailecRegular !important;
  font-size: 14 !important;
}
.MuiInputBase-input::placeholder {
  font-size: 14px !important;
  font-family: SailecRegular !important;
  color: #8d92a3 !important;
  opacity: 1 !important;
  font-size: 14 !important;
}
.MuiFormHelperText-root {
  font-family: SailecRegular !important;
}
.makeStyles-textFieldBorder-3
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.makeStyles-textFieldBorder-3 .MuiOutlinedInput-notchedOutline {
  border-radius: 0px 2px 2px 0px !important;
  border-left: 0px !important;
  transform: translate(-2px, 0px) !important;
}
.MuiTab-textColorSecondary.Mui-selected #Path_1492,.MuiTab-textColorSecondary.Mui-selected #Path_1491{
fill: #f50057!important;
}
/* .MuiTab-textColorSecondary #Path_1500{
fill: rgb(141, 146, 163)!important;
} */
.carousel.slide {
  text-align: center !important;
}
.carousel-caption {
  color: #000 !important;
  position: initial !important;
}
.carousel-caption h3 {
  font-size: 18px !important;
}
.carousel-indicators li {
  border-bottom: none !important;
  border-top-width: 2px !important;
  height: 6px !important;
  border-radius: 50% !important;
  width: 8px !important ;
  background: #fa396f !important;
}
.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
/*
CSS for custom Helper text for Paper fields
*/
.customHelperText-contained {
  margin: 8px 14px 0 !important;
}
.customHelperText-root {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 8px;
  min-height: 1em;
  text-align: left;
  font-family: SailecRegular !important;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
}
.customHelperText-root.Mui-error {
  color: #f44336;
}
.modal-content {
  position: fixed !important;
  bottom: 0px !important;
  border-radius: 0px !important;
}
.modal-dialog {
  position: none !important;
  width: none !important;
  margin: 0px !important;
}
.picker-container .picker-column .picker-item.picker-item-selected {
  font-family: SailecMedium !important;
  font-size: 20px !important;
}

/* mui slider */
.MuiSlider-root.MuiSlider-vertical{
  height: 180%!important;
}
.MuiSlider-vertical{
  color: transparent!important;
}
.makeStyles-sliderButton-202 .MuiSlider-rail,.makeStyles-sliderButton-239 .MuiSlider-rail,.makeStyles-sliderButton-9 .MuiSlider-rail{
  color: #e6e6e6!important;
}
.MuiSlider-mark{
  width: 10px!important;
  height:1px!important;
  color:#8D92A3!important;
}
.MuiSlider-vertical .MuiSlider-thumbColorSecondary{
  border-style: solid!important;
  border-width: 10px!important;
  border-top-color: transparent!important;
  border-left-color: #f50057!important;
  transform: translate(-14px, 0px)!important;
  border-radius: 0px!important;
  color:transparent!important;
  }
  .MuiSlider-active{
    box-shadow: none!important;
}
.MuiSlider-markActive{
  background:#8D92A3!important;
}
/* slider top */
.MuiLinearProgress-colorPrimary .MuiLinearProgress-bar{
  background: #5CB85C!important;
}

/* regiser q 2 */
.buttonTab button{
  max-width: 100%!important;
  border-radius: 5px!important;
  border:2px solid #f5f5f5!important;
  font-family:SailecMedium!important;
  font-size: 16px!important;
  text-transform: capitalize!important;
  margin-top:15px!important ;
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}
.buttonTab .MuiTabs-indicator{
  background: transparent!important;
}
.buttonTab .MuiTab-textColorPrimary.Mui-selected{
  color:#5CB85C!important;
  border-color: #5CB85C!important;
}
.MuiButtonBase-root:focus{
  outline:0px!important;
}
/* register partner search */
.partner-btn .MuiBottomNavigationAction-root.Mui-selected,.partner-btn .MuiBottomNavigationAction-root.Mui-selected:focus{
  background: #f50057!important;
  border-radius: 6px!important;
  color:#fff!important;
  margin: 0px 3px!important;
  padding-top: 8px!important;
  outline:none!important;
}
.MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly{
  padding-top: 8px!important;
  background: #faf9f7!important;
margin: 0px 3px!important;
border:1px solid #ebedeb!important;
border-radius: 6px!important;
color: rgba(0, 0, 0, 0.54)!important;
}
.MuiBottomNavigationAction-label{
  opacity: 1!important;
}
.MuiSlider-rail{
  background-color: #dedede!important;
}
.sliderButtonStyle .MuiSlider-thumb,.sliderButtonStyle .MuiSlider-thumb,.sliderButtonStyle .MuiSlider-thumb{
  width: 17px!important;
  height: 17px!important;
  color: #fff!important;
  border: 2px solid #f50057!important;
  margin-top:-8px!important;
}
/* discover */
.kZZOPs{
  width:10px!important;
  
}
.dHQcjo{
  left: -7px!important;
  padding-right:25px!important;
}
.aoTUt{
  right: -9px!important; 
  padding-left:25px!important;
}
.MuiTabs-fixed button .MuiTab-wrapper{
  font-size: 14px!important;
  font-family: SailecMedium!important;
  text-transform: capitalize!important;
}
/* camera component */
.camera{
  -webkit-transform: scaleX(-1)!important;
transform: scaleX(-1)!important;
}
/* profile */
.MuiFab-primary{
  background: #5BC0DE!important;
}
#defalutButton{
  background:#8D92A3!important ;
  color:#fff!important;
}
#boxshadowcard{
  -webkit-box-shadow: 0px 3px 5px 1px rgba(212,212,212,1)!important;
-moz-box-shadow: 0px 3px 5px 1px rgba(212,212,212,1)!important;
box-shadow: 0px 3px 5px 1px rgba(212,212,212,1)!important;
}
.MuiFab-root{
  box-shadow: none!important;
}
.tabpaddings div:only-child{
  padding: 0px;
}
.mxTab{
  margin: 15px 4px 0px 4px!important;
}
.rowPadding{
  padding:10px!important;
  background-color: #fff!important;
  border-radius: 5px!important;
}
.rowPadding2{
  padding:10px!important;
  background-color: #fff!important;
  border-radius: 5px!important;
  margin-top: 10px!important;
}
.profileColor{
  color:#f50057!important;
  font-family:SailecBold!important; 
}
.MuiCardHeader-root{
  padding:0px 0px 10px 0px!important;
  border-bottom: 1px solid #e3e3e3!important;
  margin-bottom: 8px;
}
.profileCard{
  background: #fff!important;
  border-color: transparent!important;
  border-width: 0px!important;
}
.profileCard .MuiCardHeader-avatar{
  margin-right: 10px!important;
}
.mt-10{
  margin-top:10px!important;
}
.Containerbg{
  padding: 0px 10px!important;
  background-color: #ebebeb;
}
.textFieldcard .MuiInputBase-input{
  padding: 13px 5px 17px!important;
}
.DialogSub .MuiPaper-rounded{
  position: absolute;
  width: 100%;
  margin: 0px;
  bottom: 0;
}
.dialogContentP{
padding: 8px 11px!important;
}
.col-center{
  margin-left: auto!important;
  margin-right: auto!important;
  background-image:url(./components//assets//images/starsBg2.png) ;
  /* background-size: cover;
  background-repeat: no-repeat; */
}
.fab-width{
  width: 142px!important;
  height: 142px!important;
  background-color: rgba(92, 184, 92, 0.2)!important;
}
.success-center{
  align-items: center;
    justify-content: center;
    height: 100vh;
}
.success-m-t{
  margin-top:120px!important;
}
.link-color{
  color: #fff;
}

.StripeElement {
  background-color: #faf9f7 !important;
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
  padding: 18.5px 14px !important;
  height: auto !important;
  font-family: '"Roboto", "Helvetica", "Arial", sans-serif' !important;
}
.achorWidth{
  width:100%;
}
.StripeElement--invalid {
  border-color: #fa755a !important;
}

.btn-full {
  height: 50px;
  margin-top: 20px !important;
  font-family: "SailecRegular" !important;
  font-size: 16px !important;
}
.lifestyle button.Mui-selected{
  border-radius: 6px;
  border:2px solid #f50057;
  color:#f50057;
}
.MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly{
  padding-top: 8px!important;
  background: #faf9f7!important;
  margin: 0px 3px!important;
  border:1px solid #ebedeb!important;
  border-radius: 6px!important;
}
.MuiBottomNavigationAction-iconOnly{
  font-size:14px!important ;
  font-family:SailecRegular!important;
}
.stepperBg{
  background-color: transparent!important;
}
.stepperBg div.MuiMobileStepper-dots:first-child{
  margin:0 auto;
}
.stepperBg .MuiMobileStepper-dotActive{
  background-color: #fff;
}
.stepperBg .MuiMobileStepper-dot{
  border: 1px solid #fff;
}
.sliderWidth{
  width: 100%;
}
@media only screen and (min-width: 400px){
  .discoverImg,.discoverAvatar .MuiAvatar-circle{
height: 170px!important;
}
}
@media only screen and (min-width: 420px){
  .discoverImg,.discoverAvatar .MuiAvatar-circle{
  height: 190px!important;
  }
  }

