header.navBar {
  background: transparent;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: none;
  -webkit-box-shadow: inset 0px 34px 29px -20px rgba(0,0,0,0.26);
-moz-box-shadow: inset 0px 34px 29px -20px rgba(0,0,0,0.26);
box-shadow: inset 0px 34px 29px -20px rgba(0,0,0,0.26);
}
.MuiTypography-root.title {
  flex-grow: 1;
}
.MuiButton-root.buttonLogin {
  font-size: 12px;
}
.MuiButton-root.registerButton {
  text-transform: capitalize;
  margin-top: 20px;
  font-size: 16px;
  /* padding: 15px 0px; */
  width: 100%;
  height:48px;
}
.MuiInputBase-root {
  background: #faf9f7 !important;
  border-radius: 5px !important;
}
.MuiButton-root.buttonTransparent2 {
  border: 2px solid #fff;
  letter-spacing: 2px;
  width: 100%;
  font-size: 18px;
  height:46px;
  color: #fff;
}
.textCapitalize{
  text-transform: capitalize;
  font-weight: 400;
}
.MuiButton-root.buttonTransparent {
  border: 2px solid #fff;
  letter-spacing: 2px;
  width: 100%;
  font-size: 14px;
  height:46px;
  font-family: SailecBold !important;
  color: #fff;
}
.MuiButton-root.buttonFull {
  margin-top: 20px;
  height:46px;
  font-size: 14px;
  width: 100%;
  letter-spacing: 2px;
}
.memberTransform {
  z-index: -1000;
  transform: translate(0px, -25px);
}
.seo-heading .loginbutton{
  margin-top: 20px;
  height: 46px;
  font-size: 18px;
  width: 100%;
  background-color: #f1f3f8;
  color: #fa396f;
  margin-top: -10px;
  margin-bottom: 25px;
}
.alreadyRegister .loginbutton{
  margin-top: 20px;
  height: 46px;
  letter-spacing: 2px;
  width: 100%;
  background-color: #f1f3f8;
  color: #fa396f;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: -10px;
  margin-bottom: 25px;
}
.customCard {
  border-radius: 4px;
}
.customCardBordered {
  border: 1px solid #ebeced;
}
.customCardHeight {
  height: 200px;
}
.transVideo,
.transVideoSec {
  transform: translate(0px, -115px);
}
.homeForm {
  border-radius: 4px;
  background-color: #ffffff;
  transform: translate(0px, -45px);
}
.radio {
  color: #5bc0de !important;
}
.radioFemale {
  color: #f50057 !important;
}
.formControlHome{
  width:100%;
  margin-top:10px;
}
.SelectHome{
  height:48px;
  padding:6px;
}
.MuiSelect-root:focus{
  background: #faf9f7 !important;
  border-radius: 5px !important;
}

